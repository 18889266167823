* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Playfair Display", serif;
}
html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}
/* the color palette used for the website */
/* https://colorhunt.co/palette/191a191e51284e9f3dd8e9a8 */

/* css to highlight the words in the website */
.color-green,
.card-title {
  color: #4e9f3d;
}
/*  css for the resume download button */
.download-button {
  position: relative;
  border-width: 0;
  color: white;
  font-size: 15px;
  font-weight: 600;
  border-radius: 4px;
  z-index: 1;
}

.download-button .docs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  min-height: 40px;
  padding: 0 10px;
  border-radius: 4px;
  z-index: 1;
  background-color: #242a35;
  border: solid 1px #e8e8e82d;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.download-button:hover {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.download {
  position: absolute;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 90%;
  margin: 0 auto;
  z-index: -1;
  border-radius: 4px;
  transform: translateY(0%);
  /* background-color: #01e056; */
  background-color: #4e9f3d;
  border: solid 1px #d8e9a8;
  transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1);
}

.download-button:hover .download {
  transform: translateY(100%);
}

.download svg polyline,
.download svg line {
  animation: docs 1s infinite;
}

@keyframes docs {
  0% {
    transform: translateY(0%);
  }

  50% {
    transform: translateY(-15%);
  }

  100% {
    transform: translateY(0%);
  }
}

/*css for titles */
.title {
  font-size: 2rem;
  text-align: center;
  margin-top: 2rem;
  /* letter-spacing: 0.3rem; */
  margin-bottom: 4rem;
}
/* css for all paragraphs */
p {
  font-size: 1.1rem;
  font-weight: 300;
}

/* css for button on contact section */
.nav-btn:hover {
  background-color: #d8e9a8;
}

/* css for icons of contact section */
.social-icon {
  width: 2.5rem;
}
.social-icon:hover {
  transform: scale(1.5); /* increase to 1.1 times original size */
  transition: transform 0.3s ease-in-out;
}
